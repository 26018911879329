.wrapper {
	padding: 12px 16px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	font-size: 16px;
	color: var(--text);
	background: transparent;
	width: 100%;
	backdrop-filter: blur(20px);
	border: 1px solid var(--gray-10);
	border-radius: 10px;
	z-index: 10;
    &.isActive {
        z-index: 11;
    }

	span {
		display: block;
		font-size: 16px;
		color: var(--text);
		font-family: var(--font-title), sans-serif;
		&.placeholder {
			font-size: 16px;
			color: var(--text);
			font-family: var(--font-title), sans-serif;
		}
	}
	&.loading {
		cursor: inherit;
	}
}

.label {
	transition: 200ms ease-out;
	color: var(--gray);
	margin-bottom: 8px;
	span {
		color: #ff4b60;
	}
}

.icon {
	&.loading {
		animation: spin 0.7s linear infinite;

		@keyframes spin {
			100% {
				transform: rotate(360deg);
			}
		}
	}
}

.listWrapper {
	max-height: 180px;
	overflow-y: scroll;
	background: var(--background);
	border: 1px solid var(--gray-10);
	box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	padding: 8px 0;
	position: absolute;
	width: 100%;
	z-index: 100;
	left: 0;
	top: 100%;
	transform: translateY(4px);
	-ms-overflow-style: none;
	overflow: -moz-scrollbars-none;

	&::-webkit-scrollbar {
		width: 0;
	}

	.listInner {
		height: max-content;
	}
	.listItem {
		color: var(--text);
		font-family: var(--font-title), sans-serif;

		display: block;
		cursor: pointer;
		padding: 8px 16px;
		transition: ease 0.15s;
		&:hover {
            background: var(--foreground);
			transition: ease 0.15s;
		}
		&.active {
            background: var(--foreground);
			transition: ease 0.15s;
		}
	}
}

.searchInput {
	width: 100%;
	border: none;
	outline: none;
	background: transparent;
	color: var(--text);
	font-family: var(--font-title), sans-serif;
	font-size: 16px;
}

.not-available {
	cursor: not-allowed !important;
	&:hover{
		background: transparent !important;
	}
}